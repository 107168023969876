import { useSelector } from 'react-redux';
import {
  personSelector,
  member as memberSelector,
  isPrivateCustomer as isPrivateCustomerSelector
} from '~/App/shared/selectors/sessionSelector';
import { useState } from 'react';
import {
  getIsOrgAccountDetailsComplete,
  getIsPrivateAccountDetailsComplete
} from '~/helpers/member';

export type AccountVerificationStatus =
  | 'VERIFY_PRIV_CUSTOMER_BANKID_AND_PHONE'
  | 'VERIFY_PRIV_CUSTOMER_BANKID'
  | 'VERIFY_PRIV_CUSTOMER_PHONE'
  | 'VERIFY_PRIVATE_MEMBER_DATA'
  | 'VERIFY_ORG_PERSON_BANKID_AND_PHONE'
  | 'VERIFY_ORG_PERSON_BANKID'
  | 'VERIFY_ORG_PERSON_PHONE'
  | 'VERIFY_ORG_MEMBER_DATA';

export const useAccountVerificationStatus = () => {
  const member = useSelector(memberSelector);
  const { addresses, memberType } = member;
  const { phoneNumberVerified, bankidVerified } =
    useSelector(personSelector) ?? {};
  const isPrivateCustomer = useSelector(isPrivateCustomerSelector);
  const [
    isBankIdAndPhoneVerificationPending,
    setIsBankIdAndPhoneVerificationPending
  ] = useState(false);
  const [isOnlyBankIdVerificationPending, setIsOnlyBankIdVerificationPending] =
    useState(false);
  const [isOnlyPhoneVerificationPending, setIsOnlyPhoneVerificationPending] =
    useState(false);

  const pendingStatuses = {
    setIsBankIdAndPhoneVerificationPending,
    setIsOnlyBankIdVerificationPending,
    setIsOnlyPhoneVerificationPending
  };

  const mainAddress = addresses?.[0];

  const hasCountry = Boolean(mainAddress?.country);
  if (!hasCountry) {
    return {
      accountVerificationStatus: isPrivateCustomer
        ? ('VERIFY_PRIVATE_MEMBER_DATA' as const)
        : ('VERIFY_ORG_MEMBER_DATA' as const),
      pendingStatuses
    };
  }

  const isSwedish = mainAddress?.country === 'se';

  if (isPrivateCustomer && isSwedish) {
    const getAccountVerificationStatus = ():
      | AccountVerificationStatus
      | undefined => {
      /*
        It's a 2-step verification. First bankId is veryfied and next phone number.
        The isVerificationPending flag must be used to prevent the "VERIFY_PRIV_CUSTOMER_PHONE" 
        toggle if bankId is successfully veryfied.
      */
      if (
        isBankIdAndPhoneVerificationPending ||
        (!phoneNumberVerified && !bankidVerified)
      ) {
        return 'VERIFY_PRIV_CUSTOMER_BANKID_AND_PHONE';
      }
      if (
        isOnlyBankIdVerificationPending ||
        (phoneNumberVerified && !bankidVerified)
      ) {
        return 'VERIFY_PRIV_CUSTOMER_BANKID';
      }
      if (!phoneNumberVerified && bankidVerified) {
        return 'VERIFY_PRIV_CUSTOMER_PHONE';
      }
    };

    return {
      accountVerificationStatus: getAccountVerificationStatus(),
      pendingStatuses
    };
  }

  if (isPrivateCustomer && !isSwedish) {
    const getAccountVerificationStatus = ():
      | AccountVerificationStatus
      | undefined => {
      const isPrivateAccountDetailsComplete =
        getIsPrivateAccountDetailsComplete(mainAddress);

      if (!isPrivateAccountDetailsComplete) {
        return 'VERIFY_PRIVATE_MEMBER_DATA';
      }
      if (!phoneNumberVerified) {
        return 'VERIFY_PRIV_CUSTOMER_PHONE';
      }
    };

    return {
      accountVerificationStatus: getAccountVerificationStatus(),
      pendingStatuses
    };
  }

  const isOrgAccountDetailsComplete = getIsOrgAccountDetailsComplete(
    mainAddress,
    member?.person
  );

  if (memberType === 'ORGANISATION_PERSON' && isSwedish) {
    const { isSoleProprietor } = member?.person?.organisation ?? {};

    const getAccountVerificationStatus = ():
      | AccountVerificationStatus
      | undefined => {
      if (
        isBankIdAndPhoneVerificationPending ||
        (isSoleProprietor && !phoneNumberVerified && !bankidVerified)
      ) {
        return 'VERIFY_ORG_PERSON_BANKID_AND_PHONE';
      }
      if (
        isOnlyBankIdVerificationPending ||
        (isSoleProprietor && phoneNumberVerified && !bankidVerified)
      ) {
        return 'VERIFY_ORG_PERSON_BANKID';
      }
      if (isOnlyPhoneVerificationPending || !phoneNumberVerified) {
        return 'VERIFY_ORG_PERSON_PHONE';
      }
      if (!isOrgAccountDetailsComplete) {
        return 'VERIFY_ORG_MEMBER_DATA';
      }
    };

    return {
      accountVerificationStatus: getAccountVerificationStatus(),
      pendingStatuses
    };
  }

  if (memberType === 'ORGANISATION_PERSON' && !isSwedish) {
    const getAccountVerificationStatus = ():
      | AccountVerificationStatus
      | undefined => {
      if (isOnlyPhoneVerificationPending || !phoneNumberVerified) {
        return 'VERIFY_ORG_PERSON_PHONE';
      }
      if (!isOrgAccountDetailsComplete) {
        return 'VERIFY_ORG_MEMBER_DATA';
      }
    };
    return {
      accountVerificationStatus: getAccountVerificationStatus(),
      pendingStatuses
    };
  }

  return {
    accountVerificationStatus: undefined,
    pendingStatuses
  };
};
