import React, { useEffect } from 'react';
import { mq } from '@kvdbil/components';
import styled, { createGlobalStyle, css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { resetMenuDrawer } from '../shared/actions/drawerMenu';

type Direction = 'left' | 'right' | 'top' | 'bottom';

const getTransform = (direction: Direction) => {
  switch (direction) {
    case 'left':
      return 'translateX(-100%)';
    case 'right':
      return 'translateX(100%)';
    case 'top':
      return 'translateY(-100%)';
    case 'bottom':
      return 'translateY(100%)';
    default:
      return 'translateX(-100%)';
  }
};

const DrawerContainer = styled.div<{ isOpen: boolean; direction: Direction }>`
  top: 0;
  position: fixed;
  ${({ direction }) => css`
    ${direction}: 0;
    transform: ${getTransform(direction)};
  `}

  background-color: ${({ theme }) => theme.colors.background.light};
  transition: transform 0.2s ease-in-out;

  z-index: 102;
  width: 100%;
  height: 100%;

  ${mq('tablet')} {
    max-width: 425px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: none;
    `}
`;

const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(29, 29, 27, 0.4);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transition: opacity 0.2s ease-in-out;
  z-index: 101;
`;

const ScrollLock = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

type Props = {
  isOpen: boolean;
  useBackdrop?: boolean;
  useScrollLock?: boolean;
  onBackdropClick?(): void;
  direction?: Direction;
  unmountOnClose?: boolean;
  children: React.ReactNode;
};

export const Drawer = ({
  isOpen,
  useBackdrop = true,
  useScrollLock = true,
  onBackdropClick,
  direction = 'right',
  unmountOnClose = true,
  children
}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      !isOpen && dispatch(resetMenuDrawer());
    }, 5000); // timeout number reflects CSS transition time (drawer fade)

    return () => clearTimeout(timeoutId);
  }, [isOpen, dispatch]);

  if (!isOpen && unmountOnClose) {
    return null;
  }

  return (
    <>
      {isOpen && useScrollLock && <ScrollLock />}

      {useBackdrop && (
        <Backdrop
          data-testid="drawer-component-backdrop"
          isOpen={isOpen}
          onClick={onBackdropClick}
        />
      )}
      <DrawerContainer isOpen={isOpen} direction={direction}>
        {children}
      </DrawerContainer>
    </>
  );
};
