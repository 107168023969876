import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  isSupported,
  MessagePayload,
  onMessage,
  Unsubscribe
} from 'firebase/messaging';

import {
  firebaseApiKey,
  firebaseAppId,
  firebaseAuthDomain,
  firebaseMeasurementId,
  firebaseMessagingSenderId,
  firebaseProjectId,
  firebaseStorageBucket
} from '~/config/public/environment';

import logger from '~/helpers/logger';

let unsubscribe: Unsubscribe | null = null;

export async function registerServiceWorker() {
  try {
    if ('serviceWorker' in navigator) {
      const parameters = new URLSearchParams({
        apiKey: firebaseApiKey as string,
        messagingSenderId: firebaseMessagingSenderId as string,
        projectId: firebaseProjectId as string,
        appId: firebaseAppId as string,
        measurementId: firebaseMeasurementId as string
      });

      const url = new URL(`firebase-messaging-sw.js`, window.location.origin);
      url.search = parameters.toString();

      await navigator.serviceWorker.register(`${url.toString()}`);
    } else {
      throw new Error('Service Worker not supported.');
    }
  } catch (error) {
    logger.error('An error occurred while retrieving token. ', error);
  }
}

export async function unRegisterServiceWorker() {
  try {
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
        break; // Exit the loop once the specific service worker is unregistered
      }
    } else {
      throw new Error('Service Worker not supported.');
    }
  } catch (error) {
    logger.error('An error occurred while retrieving token. ', error);
  }
}

export async function requestNotificationPermission() {
  try {
    return await Notification.requestPermission();
  } catch (error) {
    logger.error('Error requesting notification permission: ', error);
  }
}

export function receiveMessage(payload: MessagePayload) {
  // Customize notification here
  const notificationTitle = payload?.notification?.title;
  const notificationOptions = {
    title: payload?.notification?.title ?? 'Background Message Title',
    body: payload?.notification?.body ?? 'Background Message Body',
    icon: payload?.notification?.image ?? '/images/favicon-32.png'
  };

  if (notificationTitle) {
    new Notification(notificationTitle, notificationOptions);
  }
}

export async function init() {
  try {
    const supported = await isSupported();

    if (!supported) {
      throw new Error('Firebase Messaging is not supported.');
    }

    const permission = await requestNotificationPermission();

    // Initialize Firebase
    if (permission === 'granted') {
      const app = initializeApp({
        apiKey: firebaseApiKey,
        authDomain: firebaseAuthDomain,
        projectId: firebaseProjectId,
        storageBucket: firebaseStorageBucket,
        messagingSenderId: firebaseMessagingSenderId,
        appId: firebaseAppId,
        measurementId: firebaseMeasurementId
      });
      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = getMessaging(app);
      if (unsubscribe) {
        unsubscribe();
      }
      unsubscribe = onMessage(messaging, receiveMessage);
      await registerServiceWorker();
    } else {
      logger.info('Unable to get permission to notify.');
    }
  } catch (error) {
    logger.error('An error occurred while initializing Firebase: ', error);
  }
}

export const cleanUp = async () => {
  await unRegisterServiceWorker();

  if (unsubscribe) {
    unsubscribe();
  }
};
