import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import { Spinner, theme, mq } from '@kvdbil/components';
import { renderRoutes } from '~/config/routes/renderRoutes';
import { useTranslation } from '~/Locale';
import SiteMessage from '~/App/shared/components/SiteMessage';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactButton from './components/ContactButton';
import routes from '~/routes';
import WebsocketIndividualConnection from './shared/components/WebsocketIndividualConnection';
import UpdateViewingAuction from './components/UpdateViewingAuction';
import Snackbars from './components/Snackbars';
import { showFooter } from '~/helpers/footerHelpers';
import { showHeader } from '~/helpers/headerHelpers';
import AppLoaderOverlay from './shared/components/AppLoaderOverlay';
import { appIsLoading, messagesBottom } from './shared/selectors/global';
import { auctionListIsShowingFilters } from './shared/selectors/auctionListSelector';
import { dismissMessage } from './shared/actions/messages';
import styled from 'styled-components';
import { ReduxDispatch } from './shared/interfaces/Redux';
import { translateRoute } from '~/App/shared/localization';
import CanonicalHeadLink from './components/CanonicalHeadLink';
import { SiteMessageRecord } from '~/config/generated/graphql';
import LanguageModal from '~/App/shared/localization/components/LanguageModal';
import KvdHelper from './components/KvdHelper';
import { useFeatureFlag } from './components/FeatureFlags';
import { useInitFirebase } from './firebase/useInitFirebase';

const Container = styled.div`
  position: fixed;
  z-index: 12;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.gray};
`;

const FooterWrapper = styled.div<{ hideOnMobile: boolean }>`
  ${({ hideOnMobile }) =>
    hideOnMobile &&
    `
    display: none;
    ${mq('tablet')} {
      display: block;
    }
    `}
`;

interface Props {
  appIsLoading: boolean;
  showAuctionFilters: boolean;
  messagesBottom: SiteMessageRecord[];
  onMessageDismiss: (id: string, type: string) => void;
}
export const App = ({
  appIsLoading,
  showAuctionFilters,
  messagesBottom,
  onMessageDismiss
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isFooter = showFooter(t, location.pathname);
  const isHeader = showHeader(t, location.pathname);
  const { value: showKvdHelper } = useFeatureFlag('kvd-helper');

  const isAuctionPage =
    location.pathname &&
    location.pathname === translateRoute(t, '/ROUTES.AUCTIONS');

  const compiledRoutes = routes(t);

  useInitFirebase();

  return (
    <div>
      {appIsLoading && (
        <AppLoaderOverlay>
          <Spinner color={theme.colors.gray.light4} size="large" speed="fast" />
        </AppLoaderOverlay>
      )}
      <UpdateViewingAuction />
      <CanonicalHeadLink pathname={location.pathname} />
      {isHeader && <Header />}
      <ContentContainer>{renderRoutes(compiledRoutes)}</ContentContainer>
      <ContactButton location={location.pathname} />
      <WebsocketIndividualConnection />
      <Container>
        {messagesBottom &&
          messagesBottom.length > 0 &&
          messagesBottom.map((message, key) => (
            <SiteMessage
              key={key}
              type={message.siteMessageType ?? ''}
              onDismiss={() =>
                onMessageDismiss(
                  message.id,
                  message.siteMessageType ?? 'Information'
                )
              }
            >
              {message.message ?? ''}
            </SiteMessage>
          ))}
      </Container>
      <Snackbars />
      <FooterWrapper
        hideOnMobile={showAuctionFilters && Boolean(isAuctionPage)}
      >
        {isFooter && <Footer />}
      </FooterWrapper>
      {/* TODO: FIX */}
      {/* <DidomiHandler /> */}

      {showKvdHelper && <KvdHelper location={location.pathname} />}

      <LanguageModal />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  appIsLoading: appIsLoading,
  messagesBottom: messagesBottom,
  showAuctionFilters: auctionListIsShowingFilters
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  onMessageDismiss: (id: string, type: string) =>
    dispatch(dismissMessage(id, type))
});

const exportedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default exportedApp;
